import React, { useState } from 'react';
import usePermissions from '../hooks/usePermissions';
import TableWrapper from '../utils/TableWrapper';
import EditTimetableEvent from './EditTimetableEvent';
import Modal from './Modal';
import DeleteTimetableEvent from './DeleteTimetableEvent';
import useGetUserRole from '../hooks/useGetUserRole';

function TimetableEvents({ timetableEvents }) {
  const [editTimetableEventModalOpen, setEditTimetableEventModalOpen] =
    useState(false);
  const [deleteTimetableEventModalOpen, setDeleteTimetableEventModalOpen] =
    useState(false);
  const [event, setEvent] = useState({});
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleEditTimetableEventModal = () => {
    setEditTimetableEventModalOpen(!editTimetableEventModalOpen);
  };

  const toggleDeleteTimetableEventModal = () => {
    setDeleteTimetableEventModalOpen(!deleteTimetableEventModalOpen);
  };

  const columns = [
    // { title: 'Cohort Name', field: 'name' },
    { title: 'Title', field: 'title' },
    {
      title: 'Start Date',
      field: 'startDate',
      render: (row) => <p>{new Date(row.startDate).toLocaleString()}</p>,
    },
    {
      title: 'End Date',
      field: 'endDate',
      render: (row) => <p>{new Date(row.endDate).toLocaleString('en-us')}</p>,
    },
    {
      title: 'Actions',
      hidden: !permissions?.includes('UpdateTimetableEvent'),
      render: (row) => (
        <div className="flex space-x-2">
          {(permissions?.includes('UpdateTimetableEvent') ||
            role === 'SuperAdmin') && (
            <button
              className="bg-claret-500 text-white px-6 py-1 rounded-md"
              type="button"
              onClick={() => {
                toggleEditTimetableEventModal();
                setEvent(row);
              }}
            >
              Edit
            </button>
          )}
          {(permissions?.includes('DeleteTimetableEvent') ||
            role === 'SuperAdmin') && (
            <button
              className="bg-red-500 text-white px-6 py-1 rounded-md"
              type="button"
              onClick={() => {
                toggleDeleteTimetableEventModal();
                setEvent(row);
              }}
            >
              Delete
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="mt-4">
      <TableWrapper
        title="List of Timetable Events"
        columns={columns}
        data={timetableEvents}
      />
      <Modal
        modalOpen={editTimetableEventModalOpen}
        toggleModal={toggleEditTimetableEventModal}
        title="Update Timetable Event"
      >
        <EditTimetableEvent
          appointmentData={event}
          toggleModal={toggleEditTimetableEventModal}
        />
      </Modal>
      <Modal
        modalOpen={deleteTimetableEventModalOpen}
        toggleModal={toggleDeleteTimetableEventModal}
        title="Delete Timetable Event"
      >
        <DeleteTimetableEvent
          toggleDeleteTimetableEventModal={toggleDeleteTimetableEventModal}
          timetableEventId={event?.id}
        />
      </Modal>
    </div>
  );
}

export default TimetableEvents;
