import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import Select from 'react-select';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import Modal from '../components/Modal';
import OnboardStudents from '../components/OnboardStudents';
import TableWrapper from '../utils/TableWrapper';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import usePermissions from '../hooks/usePermissions';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import RegenerateDID from '../components/RegenerateDID';
import CohortStudentsCertification from '../components/CohortStudentsCertification';
import MoveCohortDeferees from '../components/MoveCohortDeferees';
import MoveCohortDropOffs from '../components/MoveCohortDropOffs';
import IssueClaims from '../components/IssueClaims';
import EditSponsorshipCohort from '../components/EditSponsorshipCohort';
import UpdateCohortCertSettings from '../components/UpdateCohortCertSettings';
import CreateCohortCertSettings from '../components/CreateCohortCertSettings';

function CohortDetail() {
  const navigate = useNavigate();

  const [editCohortModalOpen, setEditCohortModalOpen] = useState(false);
  const [onboardStudentsModalOpen, setOnboardStudentsModalOpen] =
    useState(false);
  const [
    cohortStudentsCertificationModalOpen,
    setCohortStudentsCertificationModalOpen,
  ] = useState(false);
  const [createCertSettingsModalOpen, setCreateCertSettingsModalOpen] =
    useState(false);
  const [updateCertSettingsModalOpen, setUpdateCertSettingsModalOpen] =
    useState(false);
  const [regenerateDidModalOpen, setRegenerateDidModalOpen] = useState(false);
  const [issueClaimsModalOpen, setIssueClaimsModalOpen] = useState(false);
  const [moveCohortDefereesModalOpen, setMoveCohortDefereesModalOpen] =
    useState(false);
  const [moveCohortDropOffsModalOpen, setMoveCohortDropOffsModalOpen] =
    useState(false);
  const [selectedMoreActionOption, setSelectedMoreActionOption] =
    useState(null);

  const role = useGetUserRole();

  const permissions = usePermissions();

  const { cohortName, cohortId, cohortStatus } = useParams();

  const toggleEditCohortModal = () => {
    setEditCohortModalOpen(!editCohortModalOpen);
  };

  const toggleOnboardStudentsModal = () => {
    setOnboardStudentsModalOpen(!onboardStudentsModalOpen);
  };

  const toggleCohortStudentsCertificationModal = () => {
    setCohortStudentsCertificationModalOpen(
      !cohortStudentsCertificationModalOpen
    );
  };

  const toggleCreateCertSettingsModal = () => {
    setCreateCertSettingsModalOpen(!createCertSettingsModalOpen);
  };

  const toggleUpdateCertSettingsModal = () => {
    setUpdateCertSettingsModalOpen(!updateCertSettingsModalOpen);
  };

  const toggleRegenerateDidModal = () => {
    setRegenerateDidModalOpen(!regenerateDidModalOpen);
  };

  const toggleIssueClaimsModal = () => {
    setIssueClaimsModalOpen(!issueClaimsModalOpen);
  };

  const toggleMoveCohortDefereesModal = () => {
    setMoveCohortDefereesModalOpen(!moveCohortDefereesModalOpen);
  };

  const toggleMoveCohortDropOffsModal = () => {
    setMoveCohortDropOffsModalOpen(!moveCohortDropOffsModalOpen);
  };

  const moreActionsOptions = [
    { value: 'Regenerate_Student_DIDs', label: 'Regenerate Student DIDs' },
    { value: 'Issue_Student_Claims', label: 'Issue Students Claims' },
    { value: 'Move_Cohort_Deferees', label: 'Move Cohort Deferees' },
    { value: 'Move_Cohort_DropOffs', label: 'Move Cohort DropOffs' },
  ];

  const handleMoreActionsdropdown = (selectedOption) => {
    switch (selectedOption.value) {
      case 'Regenerate_Student_DIDs':
        toggleRegenerateDidModal();
        break;
      case 'Issue_Student_Claims':
        toggleIssueClaimsModal();
        break;
      case 'Move_Cohort_Deferees':
        toggleMoveCohortDefereesModal();
        break;
      case 'Move_Cohort_DropOffs':
        toggleMoveCohortDropOffsModal();
        break;
      default:
        break;
    }
    setSelectedMoreActionOption(null);
  };

  const columns = [
    {
      title: 'First Name ',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row.firstname}</p>,
    },
    {
      title: 'Last Name ',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row.lastname}</p>,
    },
    { title: 'Email', field: 'email' },
    { title: 'Country', field: 'country' },
    {
      title: 'Decentralized ID',
      field: 'decentralized_id',
      render: (row) => (
        <p className="max-w-xs line-clamp-2">
          {row.decentralized_id ? row.decentralized_id : 'Not generated'}
        </p>
      ),
    },
    { title: 'Academic Level', field: 'level_of_education' },
    {
      title: 'Last Login',
      field: 'last_login',
      render: (row) => (
        <p className="">
          {row.last_login
            ? new Date(row.last_login).toLocaleString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'No Record'}
        </p>
      ),
    },
    {
      title: 'Actions',
      render: (row) => (
        <button
          className={`bg-persian-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-persian-700 space-x-2 flex items-center `}
          type="button"
          onClick={() => navigate(`/users/student/${row.id}`)}
        >
          <p>More Details</p>
        </button>
      ),
    },
  ];

  const { data, isLoading } = useFetchDatav2(
    ['students', cohortId],
    '/users/student',
    {
      params: { cohort_id: cohortId },
    },
    "Couldn't get details. Please try again",
    true
  );

  const {
    data: cohortCertSettingCheck,
    isLoading: isFetchingCohortCertSettingsCheck,
  } = useFetchDatav2(
    ['cohort-cert-settings-check', cohortId],
    `/cohorts/${cohortId}/cert-settings/check`,
    {},
    'Could not check for existence of cohort cert setting. Try again later',
    !!cohortId
  );

  const renderActions = () => {
    let buttonsComponent = null;

    if (cohortStatus === 'ended') {
      if (role === 'SuperAdmin') {
        buttonsComponent = (
          <>
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleEditCohortModal}
            >
              Edit Cohort
            </button>
            {!isFetchingCohortCertSettingsCheck &&
              (cohortCertSettingCheck?.data?.data?.cert_setting_exists ? (
                <button
                  type="button"
                  className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                  onClick={toggleUpdateCertSettingsModal}
                >
                  Update Cert Setting
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                  onClick={toggleCreateCertSettingsModal}
                >
                  Create Cert Setting
                </button>
              ))}
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleCohortStudentsCertificationModal}
            >
              Certify Students
            </button>
          </>
        );
      } else {
        buttonsComponent = permissions?.includes('UpdateCohort') && (
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
            onClick={toggleEditCohortModal}
          >
            Edit Cohort
          </button>
        );
      }
    } else {
      buttonsComponent =
        role === 'SuperAdmin' ? (
          <>
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleEditCohortModal}
            >
              Edit Cohort
            </button>
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleOnboardStudentsModal}
            >
              Add Students
            </button>
            <div className="flex items-center">
              <Select
                value={selectedMoreActionOption}
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={moreActionsOptions}
                isClearable
                onChange={(selectedOption) => {
                  setSelectedMoreActionOption(selectedOption);
                  handleMoreActionsdropdown(selectedOption);
                }}
                placeholder="More Actions"
              />
            </div>
          </>
        ) : (
          <>
            {permissions?.includes('UpdateCohort') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleEditCohortModal}
              >
                Edit Cohort
              </button>
            )}
            {permissions?.includes('OnboardStudents') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleOnboardStudentsModal}
              >
                Add Students
              </button>
            )}
          </>
        );
    }

    return buttonsComponent;
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between items-center">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/cohorts"
            className="flex items-center"
          >
            <GridViewIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Cohorts</p>
          </NavLink>
        </Breadcrumbs>
        <div className="space-x-4 flex items-center">{renderActions()}</div>
      </div>
      <Modal
        modalOpen={editCohortModalOpen}
        toggleModal={toggleEditCohortModal}
        title="Update Sponsorship Cohort"
      >
        <EditSponsorshipCohort
          cohortId={cohortId}
          toggleModal={toggleEditCohortModal}
        />
      </Modal>
      <Modal
        modalOpen={createCertSettingsModalOpen}
        toggleModal={toggleCreateCertSettingsModal}
        title="Create Cohort Certificate Settings"
      >
        <CreateCohortCertSettings
          cohortId={cohortId}
          toggleModal={toggleCreateCertSettingsModal}
        />
      </Modal>
      <Modal
        modalOpen={updateCertSettingsModalOpen}
        toggleModal={toggleUpdateCertSettingsModal}
        title="Update Cohort Certificate Settings"
      >
        <UpdateCohortCertSettings
          cohortId={cohortId}
          toggleModal={toggleUpdateCertSettingsModal}
        />
      </Modal>
      <Modal
        modalOpen={onboardStudentsModalOpen}
        toggleModal={toggleOnboardStudentsModal}
        title={`Onboard Learners to ${cohortName}`}
      >
        <OnboardStudents toggleModal={toggleOnboardStudentsModal} />
      </Modal>
      <Modal
        modalOpen={cohortStudentsCertificationModalOpen}
        toggleModal={toggleCohortStudentsCertificationModal}
        title={`Certify ${cohortName} Students`}
      >
        <CohortStudentsCertification
          cohortId={cohortId}
          cohortName={cohortName}
          toggleModal={toggleCohortStudentsCertificationModal}
        />
      </Modal>
      <Modal
        modalOpen={regenerateDidModalOpen}
        toggleModal={toggleRegenerateDidModal}
        title="Regenerate Missing Student Decentralized IDs"
      >
        <RegenerateDID
          cohortId={cohortId}
          cohortName={cohortName}
          toggleModal={toggleRegenerateDidModal}
        />
      </Modal>
      <Modal
        modalOpen={issueClaimsModalOpen}
        toggleModal={toggleIssueClaimsModal}
        title="Issue Claims to Cohort Students"
      >
        <IssueClaims
          cohortId={cohortId}
          cohortName={cohortName}
          toggleModal={toggleIssueClaimsModal}
        />
      </Modal>
      <Modal
        modalOpen={moveCohortDefereesModalOpen}
        toggleModal={toggleMoveCohortDefereesModal}
        title={`Move ${cohortName} Deferees`}
      >
        <MoveCohortDeferees
          cohortId={cohortId}
          cohortName={cohortName}
          toggleModal={toggleMoveCohortDefereesModal}
        />
      </Modal>
      <Modal
        modalOpen={moveCohortDropOffsModalOpen}
        toggleModal={toggleMoveCohortDropOffsModal}
        title={`Move ${cohortName} Drop-offs`}
      >
        <MoveCohortDropOffs
          cohortId={cohortId}
          cohortName={cohortName}
          toggleModal={toggleMoveCohortDropOffsModal}
        />
      </Modal>
      <div className="mt-4">
        {isLoading ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`List of Students in Sponsored Cohort - ${cohortName}`}
            columns={columns}
            data={data ? data?.data?.students : []}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </MainContent>
  );
}

export default CohortDetail;
