/* eslint-disable no-unused-vars */
import { Route, Routes } from 'react-router';
import './App.css';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Layout from './components/Layout';
import Login from './Pages/Auth/Login/Login';
import Dashboard from './Pages/Dashboard';
import Tutorials from './Pages/Tutorials';
import ProtectedRoutes from './utils/PrivateRoutes';
import Courses from './Pages/Courses';
import Modules from './Pages/Modules';
import ModuleDetail from './Pages/ModuleDetail';
import Users from './Pages/Users';
import TutorialDetail from './Pages/TutorialDetail';
import Groups from './Pages/Groups';
import Cohorts from './Pages/Cohorts';
import CohortDetail from './Pages/CohortDetail';
import GroupDetail from './Pages/GroupDetail';
import AssignmentDetail from './Pages/AssignmentDetail';
import QuizList from './Pages/QuizList';
import AddQuizQuestions from './Pages/AddQuizQuestions';
import RolesAndPermissions from './Pages/RolesAndPermissions';
import Events from './Pages/Events';
import Profile from './Pages/Profile';
import ResetPassword from './Pages/Auth/ResetPassword';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import CohortQuizAnalytics from './Pages/CohortQuizAnalytics';
import Forums from './Pages/Forums';
import ForumDetail from './Pages/ForumDetail';
import LessonDetail from './Pages/LessonDetail';
import Email from './Pages/Email';
import CohortLoginsAnalytics from './Pages/CohortLoginsAnalytics';
import Timetable from './Pages/Timetable';
import Notifications from './Pages/Notifications';
import SurveyList from './Pages/SurveyList';
import AddSurveyQuestions from './Pages/AddSurveyQuestions';
import { getNotifications } from './features/notification/notificationSlice';
import CohortModuleProgressAnalytics from './Pages/CohortModuleProgressAnalytics';
import PaidModulesStudentProgressAnalytics from './Pages/PaidModulesStudentProgressAnalytics';
import AddModule from './Pages/AddModule';
import PaidCohorts from './Pages/PaidCohorts';
import PaidCohortDetail from './Pages/PaidCohortDetail';
import CommunityTabs from './Pages/communityTabs';
import Tickets from './Pages/Tickets';
import TicketDetail from './Pages/TicketDetail';
import AnalyticsDashBoard from './Pages/Analytics';
import PaidModulesAnalytics from './Pages/PaidModulesAnalytics';
import StudentDetailsPage from './Pages/StudentDetailsPage';
import CreateLesson from './Pages/CreateLesson';
import CreateWeekAssignment from './Pages/CreateWeekAssignment';
import CohortAssignmentAnalytics from './Pages/CohortAssignmentAnalytics';
import CohortCertificationAnalytics from './Pages/CohortCertificationAnalytics';
import SurveyAnalytics from './Pages/CohortSurveyAnalytics';

const notificationSocketUrl =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/notification/socket';

function App() {
  const [isMobileView, setIsMobileView] = useState(false);
  const dispatch = useDispatch();
  const [token, setToken] = useState(localStorage.getItem('token'));

  const [notifications, setNotifications] = useState({
    count: 0,
    messages: [],
  });

  useEffect(() => {
    if (!token) {
      return;
    }
    const socketConnection = new WebSocket(
      `${notificationSocketUrl}/notification?Authorization=${localStorage.getItem(
        'token'
      )}`
    );

    socketConnection.onmessage = (event) => {
      setNotifications({
        count: JSON.parse(event.data)?.length || 0,
        messages: JSON.parse(event.data) || [],
      });
      dispatch(
        getNotifications({
          count: JSON.parse(event.data)?.length || 0,
          messages: JSON.parse(event.data) || [],
        })
      );
    };
    // setSocket(socketConnection);
  }, [dispatch, token]);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 900) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return isMobileView ? (
    <div className="flex  items-center justify-center align-middle max-h-[100vh] h-[100vh]">
      <h1 className="text-xl font-medium text-center">
        This application is only available for desktop devices
      </h1>
    </div>
  ) : (
    <Routes>
      <Route path="/*" element={<ProtectedRoutes />}>
        <Route path="/*" element={<Layout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="courses" element={<Courses />} />
          <Route path="analytics" element={<AnalyticsDashBoard />} />
          <Route
            path="analytics/cohort-quiz-analytics"
            element={<CohortQuizAnalytics />}
          />
          <Route
            path="analytics/cohort-assignment-analytics"
            element={<CohortAssignmentAnalytics />}
          />
          <Route
            path="analytics/cohort-module-progress-analytics"
            element={<CohortModuleProgressAnalytics />}
          />
          <Route
            path="analytics/cohort-logins-analytics"
            element={<CohortLoginsAnalytics />}
          />
          <Route
            path="analytics/survey-analytics"
            element={<SurveyAnalytics />}
          />
          <Route
            path="analytics/cohort-certification-analytics"
            element={<CohortCertificationAnalytics />}
          />
          <Route
            path="analytics/paid-modules"
            element={<PaidModulesAnalytics />}
          />
          <Route
            path="analytics/paid-modules-students-progress"
            element={<PaidModulesStudentProgressAnalytics />}
          />
          <Route path="courses/:courseId" element={<Modules />} />
          <Route path="courses/:courseId/addModule" element={<AddModule />} />
          <Route path="module/:moduleId" element={<ModuleDetail />} />
          <Route path="tutorials" element={<Tutorials />} />
          <Route path="tutorials/:tutorialId" element={<TutorialDetail />} />
          <Route path="groups" element={<Groups />} />
          <Route path="community" element={<CommunityTabs />} />
          <Route path="groups/:groupName/:groupId" element={<GroupDetail />} />
          <Route
            path="courses/modules/week-:weekName/:weekId/quizzes"
            element={<QuizList />}
          />
          <Route
            path="courses/modules/weeks/quiz-:quizName/:quizId/questions"
            element={<AddQuizQuestions />}
          />
          <Route
            path="courses/modules/week-:weekName/:weekId/surveys"
            element={<SurveyList />}
          />
          <Route
            path="courses/modules/weeks/survey-:surveyName/:surveyId/questions"
            element={<AddSurveyQuestions />}
          />

          <Route
            path="courses/modules/week-:weekName/:weekId/lessons/create"
            element={<CreateLesson />}
          />
          <Route
            path="courses/modules/week-:weekName/:weekId/assignments/create"
            element={<CreateWeekAssignment />}
          />

          <Route
            path="courses/modules/:moduleId/week/:weekId/lesson/:lessonId"
            element={<LessonDetail />}
          />
          <Route
            path="group/:groupId/assignment/:assignmentId"
            element={<AssignmentDetail />}
          />
          <Route
            path="assignment/:assignmentId"
            element={<AssignmentDetail weekly />}
          />
          <Route path="cohorts" element={<Cohorts />} />
          <Route
            path="cohorts=:cohortStatus/:cohortName/:cohortId"
            element={<CohortDetail />}
          />
          <Route path="paid-cohorts" element={<PaidCohorts />} />
          <Route
            path="paid-cohorts=:paidCohortStatus/:paidCohortName/:paidCohortId"
            element={<PaidCohortDetail />}
          />
          <Route path="users" element={<Users />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="users/:userId" element={<Profile />} />
          <Route
            path="users/student/:studentId"
            element={<StudentDetailsPage />}
          />
          <Route path="roles&permissions" element={<RolesAndPermissions />} />
          <Route path="events" element={<Events />} />
          <Route path="emails" element={<Email />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="tickets/:ticketid" element={<TicketDetail />} />
          <Route path="forums" element={<Forums />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="forums/:forumId" element={<ForumDetail />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password/" element={<ResetPassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}

export default App;
