import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    quizName: yup.string().required('This is a required field'),
    description: yup
      .string()
      .max(250, 'Description exceeds 250 characters')
      .required('This is a required field'),
    duration: yup.string().required('This is a required field'),
    percent_pass_mark: yup.string().required('This is a required field'),
    compulsory: yup.object().shape({
      label: yup.string().required('This is a required (from label)'),
      value: yup.string().required('This is required'),
    }),
  })
  .required();

function UpdateQuiz({
  toggleModal,
  quizName,
  quizPassMark,
  quizId,
  quizDuration,
  quizDescription,
  quizIsCompulsory,
}) {
  const { weekId } = useParams();

  const quizIsCompulsoryOptions = [
    {
      value: true,
      label: `Yes`,
    },
    {
      value: false,
      label: `No`,
    },
  ];

  console.log(quizIsCompulsory);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      quizName,
      percent_pass_mark: quizPassMark,
      duration: quizDuration,
      description: quizDescription,
      compulsory: quizIsCompulsoryOptions.find(
        (option) => option.value === quizIsCompulsory
      ),
    },
  });

  const queryClient = useQueryClient();

  const onQuizUpdateSuccess = () => {
    toast.success('Quiz successfully update!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onQuizUpdateFailure = () => {
    toast.error('Quiz update failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.put(`/quizes/${quizId}`, data);
    },
    {
      onSuccess: () => {
        onQuizUpdateSuccess();
        queryClient.invalidateQueries(['week-quizes', weekId]);
      },
      onError: onQuizUpdateFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestData = {
      ...data,
      duration: Number(data.duration),
      percent_pass_mark: Number(data.percent_pass_mark),
      is_compulsory: data?.compulsory.value,
      weekId,
    };
    mutation.mutate(requestData);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="quizName" className="text-base">
              Quiz Name
            </label>
            <input
              {...register('quizName', { required: true })}
              type="text"
              id="quizName"
              placeholder="Quiz Name"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.quizName ? 'border-red-600' : ''
              }`}
            />
            {errors.quizName && (
              <span className="text-red-600 text-xs mt-2">
                {errors.quizName?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="duration" className="text-base">
              Quiz Duration in Minuites
            </label>
            <input
              {...register('duration', { required: true })}
              type="number"
              min={10}
              id="duration"
              placeholder="Quiz Duration"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.duration ? 'border-red-600' : ''
              }`}
            />
            {errors.duration && (
              <span className="text-red-600 text-xs mt-2">
                {errors.duration?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="percent_pass_mark" className="text-base">
              Quiz Pass Mark
            </label>
            <input
              {...register('percent_pass_mark', { required: true })}
              type="number"
              min={10}
              max={100}
              id=""
              placeholder="Pass Mark"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.percent_pass_mark ? 'border-red-600' : ''
              }`}
            />
            {errors.percent_pass_mark && (
              <span className="text-red-600 text-xs mt-2">
                {errors.percent_pass_mark?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="compulsory" className="text-base">
              compulsory
            </label>
            <Controller
              name="compulsory"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={quizIsCompulsoryOptions}
                />
              )}
            />
            {errors.compulsory && (
              <span className="text-red-600 text-xs mt-2">
                {errors.compulsory?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 col-span-2">
            <label htmlFor="description" className="text-base">
              Quiz Description
            </label>
            <textarea
              rows={4}
              id="description"
              {...register('description', { required: true, maxLength: 250 })}
              className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                errors.description ? 'border-red-600 focus:outline-red-600' : ''
              }`}
              type="text"
              placeholder="Give a little description about the quiz"
            />
            {errors.description && (
              <span className="text-red-600 text-xs mt-2">
                {errors.description?.message}
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Update Quiz</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default UpdateQuiz;
